import React from 'react';
import ReactDOM from 'react-dom/client';
import HeaderComponent from './components/header';
import BannerComponent from './components/banner';
import BodyComponent from './components/body';
import TestimonialComponent from './components/testimonial';
import PricingComponent from './components/pricing';
import FaqComponent from './components/faq';
import FooterComponent from './components/footer';
import './App.css';

function App() {
  return (
    <div className="App">
             <HeaderComponent/>
             <BannerComponent/>
             <BodyComponent/>
             <TestimonialComponent/>
             <PricingComponent/>
             <FaqComponent/>
             <FooterComponent/>
    </div>
  );
}

export default App;
